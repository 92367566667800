import React from 'react'
import { Footer, Header, Marquee } from './'
import { ScrollRestoration } from '..'
import { client, slicesFetchLinks } from '../../utils/prismicHelpers'
import { getLang, getCookie, getUserLang, setUserLang } from '../../utils/helpers'
import datastore from '../../datastore'
import Prismic from 'prismic-javascript'

let cookieConsentPopup

/**
 * Default site layout component
 */
const DefaultLayout = ({ children }) => {
  const [prismicData, setPrismicData] = React.useState({ apiDoc: null, layoutDoc: null, sectors: null, sectorssall: null, jobs: null, alljobs: null, jobsall: null, countriessearch: null, countriesall: null, locations: null, locationsall: null, subsidiaries: null, countries:null })
  const lang = getLang(window.location.pathname)

  // Get the global app data from Prismic
  // UseEffect => La suite d'action est lancée si une modification / update arrive sur les données écoutées

  React.useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const apiDoc = await client.api.get()
        const [layoutDoc, subsidiarypages, allsubsidiarypages, sectors, allsectors, jobs, alljobs, countries, allcountries, locations, alllocations, subsidiaries, country, ga, marquees] = await Promise.all([
          // Récupération de la page principale
          client.getSingle('layout', { lang }),
          // Récupération données des filiales
          client.query(
            Prismic.Predicates.at('document.type', 'subsidiary_page'),
            { lang, orderings: '[my.subsidiary_page.name]', pageSize: 100 }
          ),
          client.query(
            Prismic.Predicates.at('document.type', 'subsidiary_page'),
            { lang: "*", orderings: '[my.subsidiary_page.name]', pageSize: 100 }
          ),
          // Récupération données des secteurs
          client.query(
            Prismic.Predicates.at('document.type', 'sector'),
            { lang, orderings: '[my.sector.name]', pageSize: 100 }
          ),
          client.query(
            Prismic.Predicates.at('document.type', 'sector'),
            { lang: "*", orderings: '[my.sector.name]', pageSize: 100 }
          ),
          // Récupération données des jobs
          client.query(
            Prismic.Predicates.at('document.type', 'job'),
            { lang, orderings: '[my.job.name]', pageSize: 100 }
          ),
          client.query(
            Prismic.Predicates.at('document.type', 'job'),
            { lang: "*", orderings: '[my.job.name]', pageSize: 100 }
          ),
          // Récupération données des pays
          client.query(
            Prismic.Predicates.at('document.type', 'country'),
            { lang, orderings: '[my.country.name]', pageSize: 100 }
          ),
          client.query(
            Prismic.Predicates.at('document.type', 'country'),
            { lang: "*", orderings: '[my.country.name]', pageSize: 100 }
          ),
          // Récupération données des locations
          client.query(
            Prismic.Predicates.at('document.type', 'location'),
            { lang, orderings: '[my.location.name]', pageSize: 100 }
          ),
          client.query(
            Prismic.Predicates.at('document.type', 'location'),
            { lang: "*", orderings: '[my.location.name]', pageSize: 100 }
          ),
          // Récupération données des locaux subsidiaires
          client.query(
            Prismic.Predicates.at('document.type', 'subsidiary'),
            { lang, orderings: '[my.subsidiary.name]', fetchLinks: slicesFetchLinks }
          ),
          client.query(
            Prismic.Predicates.at('document.type', 'addcountriestomap'),
            { lang }
          ),
          // Récupération du code GA
          client.query(
            Prismic.Predicates.at('document.type', 'googleanalytics'),
            { lang }
          ),
          // Récupération des marquees
          client.query(
            Prismic.Predicates.at('document.type', 'marquee'),
            { lang }
          ),
        ])

        if (layoutDoc) {
          const availableLanguages = [layoutDoc.lang]
          for (let index = 0; index < layoutDoc.alternate_languages.length; index++) {
            const al = layoutDoc.alternate_languages[index]
            availableLanguages.push(al.lang)
          }

          apiDoc.available_languages = apiDoc.languages.filter(_ => availableLanguages.indexOf(_.id) > -1)

          // Actually starting analytics up
          function addAnalytics () {
            window.dataLayer = window.dataLayer || []

            window.gtag = function gtag () {
              window.dataLayer.push(arguments)
            }

            window.gtag('js', new Date())
            window.gtag('config', window.GA_MEASUREMENT_ID)
          }

          const cookieConsent = layoutDoc.data.cookie_consent[0]

          // Reading "cookieconsent_status" cookie
          const cookieConsentStatus = getCookie('cookieconsent_status')

          // Start analytics if user consented or did not deny
          if (cookieConsentStatus === 'allow' || cookieConsentStatus === '') {
            addAnalytics()
          }

          if (cookieConsentPopup) {
            cookieConsentPopup.destroy()
            cookieConsentPopup = null
          }

          cookieConsent && window.cookieconsent.initialise({
            onInitialise: function (args) {
            },
            onPopupOpen: function (args) {
              cookieConsentPopup = this
            },
            onPopupClose: function (args) {
            },
            // Reload the page on user choice to make sure cookie is set
            onStatusChange: function (status, chosenBefore) {
              if (!getUserLang()) {
                setUserLang(lang)
              }
              // setUserLang(lang)
              window.location.reload()
            },
            palette: {
              popup: {
                background: '#01103d'
              },
              button: {
                background: '#58ff8f',
                text: '#000000'
              }
            },
            theme: 'classic',
            type: 'opt-in',
            content: {
              message: cookieConsent.message,
              dismiss: cookieConsent.button_dismiss_label,
              deny: cookieConsent.button_deny_label,
              allow: cookieConsent.button_allow_label,
              link: cookieConsent.link_policy_label,
              href: cookieConsent.link_policy_url
            }
          })

          // Récupération de toutes les filiales
          let allsubsidiarypagesglobal = [...allsubsidiarypages.results]
          if (allsubsidiarypages && allsubsidiarypages.total_pages > 1) {
            for (let index = 2; index <= allsubsidiarypages.total_pages; index++) {
              const subsidiarypageNext = await client.query(
                Prismic.Predicates.at('document.type', 'subsidiary_page'),
                { lang: "*", orderings: '[my.subsidiary_page.name]', fetchLinks: slicesFetchLinks, pageSize: 100, page: index }
              )
              if (subsidiarypageNext && subsidiarypageNext.results) {
                allsubsidiarypagesglobal = allsubsidiarypagesglobal.concat(subsidiarypageNext.results)
              }
            }
          }
          // ---
          // Récupération de tous les secteurs
          let allsectorsglobal = [...allsectors.results]
          if (allsectors && allsectors.total_pages > 1) {
            for (let index = 2; index <= allsectors.total_pages; index++) {
              const sectorNext = await client.query(
                Prismic.Predicates.at('document.type', 'sector'),
                { lang: "*", orderings: '[my.sector.name]', fetchLinks: slicesFetchLinks, pageSize: 100, page: index }
              )
              if (sectorNext && sectorNext.results) {
                allsectorsglobal = allsectorsglobal.concat(sectorNext.results)
              }
            }
          }
          // ---
          // Récupération de tous les jobs
          let alljobsglobal = [...alljobs.results]
          if (alljobs && alljobs.total_pages > 1) {
            for (let index = 2; index <= alljobs.total_pages; index++) {
              const jobNext = await client.query(
                Prismic.Predicates.at('document.type', 'job'),
                { lang: "*", orderings: '[my.job.name]', fetchLinks: slicesFetchLinks, pageSize: 100, page: index }
              )
              if (jobNext && jobNext.results) {
                alljobsglobal = alljobsglobal.concat(jobNext.results)
              }
            }
          }
          // ---
          // Récupération de tous les pays
          let allcountriesglobal = [...allcountries.results]
          if (allcountries && allcountries.total_pages > 1) {
            for (let index = 2; index <= allcountries.total_pages; index++) {
              const countryNext = await client.query(
                Prismic.Predicates.at('document.type', 'country'),
                { lang: "*", orderings: '[my.country.name]', fetchLinks: slicesFetchLinks, pageSize: 100, page: index }
              )
              if (countryNext && countryNext.results) {
                allcountriesglobal = allcountriesglobal.concat(countryNext.results)
              }
            }
          }
          // ---
          // Récupération de tous les lieux
          let alllocationsglobal = [...alllocations.results]
          if (alllocations && alllocations.total_pages > 1) {
            for (let index = 2; index <= alllocations.total_pages; index++) {
              const locationNext = await client.query(
                Prismic.Predicates.at('document.type', 'location'),
                { lang: "*", orderings: '[my.location.name]', fetchLinks: slicesFetchLinks, pageSize: 100, page: index }
              )
              if (locationNext && locationNext.results) {
                alllocationsglobal = alllocationsglobal.concat(locationNext.results)
              }
            }
          }
          // ---

          layoutDoc.currentLangPath = lang
          datastore.ga = ga
          datastore.marquees = marquees.results
          datastore.apiDoc = apiDoc
          datastore.layoutDoc = layoutDoc
          datastore.subsidiarypages = subsidiarypages.results
          datastore.sectors = sectors.results
          datastore.allsectors = allsectorsglobal
          datastore.jobs = jobs.results
          datastore.alljobs = alljobsglobal
          datastore.countriessearch = countries.results
          datastore.allcountries = allcountriesglobal
          let templocationtab = locations.results
          let locationtab = []
          for (let i = 0; i < templocationtab.length; i++) {
            let key = templocationtab[i].data.country?.uid
            if (locationtab[key] === undefined) {
              locationtab[key] = [];
            }
            locationtab[key].push(templocationtab[i])
          }
          let tempalllocationtab = alllocationsglobal
          let alllocationtab = []
          for (let i = 0; i < tempalllocationtab.length; i++) {
            let key = tempalllocationtab[i].data.country?.uid
            if (alllocationtab[key] === undefined) {
              alllocationtab[key] = [];
            }
            alllocationtab[key].push(tempalllocationtab[i])
          }
          datastore.locations = locations.results
          datastore.alllocations = alllocationsglobal
          datastore.locationssearch = locationtab
          datastore.alllocationssearch = alllocationtab
          datastore.locationssearch = locationtab
          datastore.subsidiaries = subsidiaries.results
          datastore.allsubsidiarypages = allsubsidiarypagesglobal
          datastore.countries = country.results

          setPrismicData({ apiDoc, layoutDoc, subsidiarypages: subsidiarypages.results, allsubsidiarypages: allsubsidiarypagesglobal, sectors: sectors.results, allsectors: allsectorsglobal, jobs: jobs.results, alljobs: alljobsglobal, countriessearch: countries.results, allcountries: allcountriesglobal, locations: locations.results, alllocations: alllocationsglobal, subsidiaries: subsidiaries.results, countries: country.results })
        } else {
          console.warn('Global Prismic data was not found. Make sure it exists in your Prismic repository.')
          window.location.href = '/404'
        }
      } catch (error) {
        console.error(error)
      }
    }

    // Ref param
    const query = new URLSearchParams(window.location.search)
    datastore.ref = query.get('ref') || 'Website'

    fetchPrismicData()
  }, [lang])

  if (!prismicData.layoutDoc) {
    return null
  }

  return (
    <>
      <ScrollRestoration appNodeId='root' />
      <Header layoutDoc={prismicData.layoutDoc} apiDoc={prismicData.apiDoc} />
      <Marquee layoutDoc={prismicData.layoutDoc} />
      <main key={'main-' + prismicData.layoutDoc.lang}>
        {children}
      </main>
      <Footer layoutDoc={prismicData.layoutDoc} />
    </>
  )
}

export default DefaultLayout
