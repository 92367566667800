import React from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import CSSTransitionContainer from './CSSTransitionContainer'

import './Modal.sass'

function _getScrollbarWidth () { // thx d.walsh
  const scrollDiv = document.createElement('div')
  scrollDiv.className = 'modal-scrollbar-measure'
  document.body.appendChild(scrollDiv)
  const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}
document.body.style.setProperty('--scrollbar-width', _getScrollbarWidth() + 'px')

const Modal = React.memo(({ children, className, isShown, hide }) => {
  const domEl = document.getElementById('modal-root')

  if (!domEl) return null

  // This is where the magic happens -> our modal div will be rendered into our 'modal-root' div, no matter where we
  // use this component inside our React tree
  return ReactDOM.createPortal(
    <CSSTransitionContainer
      className={`modal-overlay ${className || ''}`} classNames='transition-modal'
      timeout={250}
      isIn={isShown}
      unmountOnExit
    >
      <Helmet htmlAttributes={{ class: 'is-modal-shown' }} />
      <button onClick={hide} className='button-close' aria-label='Close' />
      <div className='modal'>
        {children}
      </div>
    </CSSTransitionContainer>
    ,
    domEl
  )
})

export default Modal
