import React from 'react'
import { Carousel, Modal, LazySection } from '..'
import { RichTextField, FirstParagraph, DataLink } from '../prismic-elements'

import './Solutions.sass'

const Solutions = ({ slice }) => {
  const [currentVideoItem, setCurrentVideoItem] = React.useState(null)

  return (
    <LazySection className='section section-cards slice-solutions'>
      <Modal isShown={currentVideoItem !== null} hide={() => setCurrentVideoItem(null)}>
        {
          currentVideoItem && <div data-oembed-provider={currentVideoItem.data.embed.provider_name} dangerouslySetInnerHTML={{ __html: currentVideoItem.data.embed.html }} />
        }
      </Modal>
      <div className='w-container'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <Carousel className='section-cards-container' pagerClassName='pager' pagerItemClassName='pager-item'>
          {
            slice.items.map((item, index) => {
              return (
                <div
                  key={index}
                  className='section-cards-item-outer'
                  style={{ '--background': `url(${item.solution.data && item.solution.data.thumbnail.url})` }}
                >
                  {
                    slice.slice_type === 'solutions' &&
                      // eslint-disable-next-line
                      <a
                        href={item.solution.data.embed.embed_url}
                        aria-label={item.solution.data.embed.title}
                        onClick={(e) => {
                          e.preventDefault()
                          setCurrentVideoItem(item.solution)
                        }}
                        className='button-icon button-icon-play w-inline-block'
                      />
                  }
                  <DataLink prismicLink={item.solution} className={'section-cards-item-inner' + (slice.slice_type === 'solutions_detail' ? ' is-full' : '')}>
                    <h3>{item.solution.data && item.solution.data.name}</h3>
                    <FirstParagraph className='section-cards-item-text' richText={item.solution.data && item.solution.data.description} textLimit={140} />
                    {
                      slice.slice_type === 'solutions_detail' && <RichTextField field={item.description} Component='div' className='section-cards-item-text' />
                    }
                    <span className='section-cards-item-link w-inline-block'>
                      <div>{item.cta_label}</div>
                    </span>
                  </DataLink>
                </div>
              )
            })
          }
        </Carousel>
      </div>
    </LazySection>
  )
}

export default Solutions
