import React from "react";
import datastore from "../../datastore";

import "./Marquee.sass";

const Marquee = ({ layoutDoc }) => {

    let marquees = datastore.marquees

    if (layoutDoc) 
    {
        return (
            <div className="headermarquee">
                <div className="w-container">
                    {marquees.length > 0 ? <hr className="marqueehr"/> : ""}
                    {marquees.map((item, index) => (
                    <div key={item.id}>
                        {item.data.documentname && item.data.documenthref ?
                        <div className="hrefmarquee">
                            <span> {item.data.documentname} : <a href={item.data.documenthref} target="_blank" rel="noopener noreferrer">Document</a> </span>
                        </div> 
                        : ""}
                        <div className="marquee">
                        <div>
                            <span title={item.data.name}>{item.data.name}</span>
                            <span title={item.data.name}>{item.data.name}</span>
                        </div>
                        </div>
                    </div>
                    ))}
                    {marquees.length > 0 ? <hr className="marqueehr"/> : ""}
                </div>
            </div>
        );
    }
    return null;
};

export default Marquee;
