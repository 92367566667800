import React from 'react'
import { RichTextField } from '../prismic-elements'
import { LazySection } from '..'
import { InView } from 'react-intersection-observer'

import './SteppedRichText.sass'

const SteppedRichText = ({ slice }) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(-1)

  return (
    <LazySection
      className={`section section-stepped-richtext ${slice.slice_label || ''}`}
    >
      <div className='section-stepped-richtext-wrapper'>
        <div className='section-stepped-richtext-counter'>
          {
          slice.items && slice.items.map((item, index) => {
            return (
              <div key={index} className={`section-stepped-richtext-counter-item ${currentStepIndex === index ? ' is-active' : ''}`}>{index + 1}</div>
            )
          })
        }
        </div>
      </div>

      {
        slice.items && slice.items.map((item, index) => {
          return (
            <InView
              key={index}
              as='div'
              className={`section-stepped-richtext-step ${currentStepIndex === index ? ' is-active' : ''}`}
              threshold={0.75}
              onChange={(inView, entry) => inView && setCurrentStepIndex(index)}
            >
              <div className='is-narrow w-container'>
                <div className='section-step-richtext-step-richtext w-richtext'>
                  <RichTextField field={item.rich_text} />
                </div>
              </div>
            </InView>
          )
        })
      }
    </LazySection>
  )
}

export default SteppedRichText
