import React from 'react'
import { Carousel, LazySection } from '..'
import { DataLink, RichTextField, FirstParagraph } from '../prismic-elements'
import './News.sass'

const News = ({ slice }) => {
  return (
    <LazySection className='section section-cards slice-news'>
      <div className='w-container'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <Carousel className='section-cards-container' pagerClassName='pager' pagerItemClassName='pager-item'>
          {
            slice.items.map((item, index) => {
              return (
                <DataLink
                  prismicLink={item.news}
                  key={index}
                  className='section-cards-item'
                  style={{ '--background': `url(${item.news.data && item.news.data.thumbnail && item.news.data.thumbnail.url})` }}
                >
                  <RichTextField field={item.news.data && item.news.data.title} Component='h3' />
                  <FirstParagraph className='section-cards-item-text' richText={item.news.data && item.news.data.description} textLimit={140} />
                </DataLink>
              )
            })
          }
        </Carousel>
      </div>
    </LazySection>
  )
}

export default News
