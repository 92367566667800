import React from 'react'
import { RichTextField } from '../prismic-elements'
import { LazySection, Carousel } from '..'

import './LogoGallery.sass'

const LogoGallery = ({ slice }) => {
  return (
    <LazySection className='section section-logo-gallery'>
      <div className='w-container'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <Carousel data-count={slice.items.length} isSlideShow slideShowInterval={4000} className='section-logo-gallery-list' pagerClassName='pager' pagerItemClassName='pager-item'>
          {
            slice.items.map((item, index) => (
              <img
                key={index}
                src={item.image.url}
                loading='lazy'
                alt={item.image.alt}
                width={item.image.dimensions.width}
                height={item.image.dimensions.height}
                className='section-logo-gallery-list-item'
              />
            ))
          }
        </Carousel>
      </div>
    </LazySection>
  )
}

export default LogoGallery
