import React from 'react'
import { DataLink } from '../prismic-elements'
import { Link as RouterLink } from 'react-router-dom'
import { getPathWithCurrentLang } from '../../utils/helpers'
import mcaLogoLight from '../../assets/images/mca-logo-light.svg'

const Footer = ({ layoutDoc }) => {
  if (layoutDoc) {
    return (
      <footer className='footer'>
        <div className='footer-container w-container'>
          <RouterLink to={getPathWithCurrentLang(layoutDoc.currentLangPath, '/')} aria-current='page' className='footer-brand w-inline-block w--current'>
            <img src={mcaLogoLight} width='46' height='19' loading='lazy' alt='MCA Logo' />
          </RouterLink>
          <div className='footer-copyright'>{layoutDoc.data.copyright}</div>
          {
            layoutDoc.data.copyright_link_items.map((item, index) => (
              <DataLink key={'copyright-item-' + index} prismicLink={item.link} className='footer-link w-inline-block'>
                <div><span className='footer-link-separator'>|</span>{item.label}</div>
              </DataLink>
            ))
          }
          <div className='footer-socials'>
            {
              layoutDoc.data.social_items.map((item, index) => (
                <DataLink key={'social-item-' + index} prismicLink={item.link} className='footer-socials-item w-inline-block'>
                  {
                    item.image && item.image.url && <img
                      className='footer-socials-item-logo'
                      src={item.image.url}
                      loading='lazy'
                      alt={item.image.alt}
                      width={item.image.dimensions.width}
                      height={item.image.dimensions.height}
                                                    />
                  }
                </DataLink>
              ))
            }
          </div>
        </div>
      </footer>
    )
  }
  return null
}

export default Footer
