import React from 'react'
import { RichTextField, DataLink } from '../prismic-elements'
import { LazySection } from '..'

const VisualAndFeature = ({ slice }) => {
  return (
    <LazySection className='section section-visual-and-feature'>
      <div className='w-container is-narrow'>
        <div className='section-visual-and-feature-container'>
          <div className='section-visual-and-feature-visual'>
            {
              slice.primary.visual_image.url &&
                <img
                  className='section-visual-and-feature-visual-image'
                  src={slice.primary.visual_image.url}
                  loading='lazy'
                  alt={slice.primary.visual_image.alt}
                  width={slice.primary.visual_image.dimensions.width}
                  height={slice.primary.visual_image.dimensions.height}
                />
            }
          </div>
          <div className='section-visual-and-feature-item'>
            <RichTextField field={slice.primary.title} Component='h3' />
            <RichTextField field={slice.primary.description} Component='div' className='section-visual-and-feature-item-text' />
            {
              slice.primary.cta_link.url &&
                <DataLink prismicLink={slice.primary.cta_link} className='button is-inverted w-inline-block'>
                  <div>{slice.primary.cta_label}</div>
                </DataLink>
            }
          </div>
        </div>
      </div>
    </LazySection>
  )
}

export default VisualAndFeature
