import React from 'react'
import { RichText } from 'prismic-reactjs'

const FirstParagraph = ({ richText, textLimit = 300, ...rest }) => {
  if (richText) {
    const text = RichText.asText(richText)
    let limitedText = text.substring(0, textLimit)

    if (text.length > textLimit) {
      // Cut only up to the last word and attach '...' for readability
      limitedText = `${limitedText.substring(0, limitedText.lastIndexOf(' '))}...`
    }

    return <div {...rest}>{limitedText}</div>
  }

  return null
}

export default FirstParagraph
