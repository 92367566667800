import datastore from './datastore'

// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
// End point à modifier selon le prismic utilisé.
export const apiEndpoint = 'https://mca-website.cdn.prismic.io/api/v2'

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = ''

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  const defaultLang = datastore.apiDoc.languages[0].id
  const currentLangPath = doc.lang !== defaultLang ? `/${doc.lang}` : ''
  const currentLangPathHome = doc.lang !== defaultLang ? `/${doc.lang}` : '/en-us'
  if (doc.type === 'homepage') {
    return `${currentLangPathHome}`
  }

  if (doc.type === 'sector') {
    return `${currentLangPath}/sectors/${doc.uid}`
  }

  // if (doc.type === 'job') {
  //   return `${currentLangPath}/applicants/careers/${doc.uid}`
  // }
 /* Retourne la route vers laquelle correspond la page associée en fonction du doc.type (à configurer dans prismic) */
  if (doc.type === 'job_offer_homepage') {
    return `${currentLangPath}/applicants/jobs`
  }

  if (doc.type === 'job_offer') {
    return `${currentLangPath}/applicants/jobs/${doc.uid}`
  }

  if (doc.type === 'solution') {
    return `${currentLangPath}/solutions/${doc.uid}`
  }

  if (doc.type === 'project_homepage') {
    return `${currentLangPath}/expertises/projects`
  }

  if (doc.type === 'project') {
    return `${currentLangPath}/expertises/projects/${doc.uid}`
  }

  if (doc.type === 'testimonial_homepage') {
    return `${currentLangPath}/expertises/testimonials`
  }

  
  if (doc.type === 'testimonial') {
    return `${currentLangPath}/expertises/testimonials/${doc.uid}`
  }

  if (doc.type === 'testimonial_homepage_2') {
    return `${currentLangPath}/news/Testimonials2`
  }

  if (doc.type === 'testimonial_2') {
    return `${currentLangPath}/news/testimonials2/${doc.uid}`
  }
  
  if (doc.type === 'news_homepage') {
    return `${currentLangPath}/news`
  }

  if (doc.type === 'news') {
    return `${currentLangPath}/news/${doc.uid}`
  }

  if (doc.type === 'company_page') {
    return `${currentLangPath}/company/${doc.uid}`
  }

  if (doc.type === 'office_page') {
    return `${currentLangPath}/offices/${doc.uid}`
  }

  if (doc.type === 'applicant_page') {
    return `${currentLangPath}/applicants/${doc.uid}`
  }

  if (doc.type === 'expertise_page') {
    return `${currentLangPath}/expertises/${doc.uid}`
  }

  if (doc.type === 'freepage') {
    return `${currentLangPath}/landingpage/${doc.uid}`
  }

  return '/'
}
