import { useSpring } from 'react-spring'
import { useState, useEffect } from 'react'
// import ResizeObserver from "resize-observer-polyfill"

const initialBounds = { left: 0, top: 0, width: 0, height: 0 }

function useBoundingClientRect (ref) {
  const [bounds, set] = useState(initialBounds)
  const [ro] = useState(
    () =>
      new window.ResizeObserver(([entry]) =>
        set(entry.target.getBoundingClientRect())
      )
  )
  // UseEffect => La suite d'action est lancée si une modification / update arrive sur les données écoutées
  useEffect(() => {
    if (ref.current) ro.observe(ref.current)
    return () => ro.disconnect()
  }, [ref, ro])
  return bounds
}

// useSpring anime des valeurs
export const useTiltEffect = (ref) => {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 }
  }))

  const { width, height } = useBoundingClientRect(ref)

  const calc = (x, y) => [
    -((height / 2 - y) / (height / 2)) * 5,
    -((width / 2 - x) / (width / 2)) * 5,
    1.01
  ]

  const trans = (x, y, s) =>
    `perspective(500px) rotateX(${-x}deg) rotateY(${y}deg) scale(${s})`

  return {
    style: {
      transform: props.xys.interpolate(trans)
    },
    onMouseLeave: () => set({ xys: [0, 0, 1] }),
    onMouseMove: (e) => {
      set({ xys: calc(e.nativeEvent.offsetX, e.nativeEvent.offsetY) })
    }
  }
}
