import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

const ScrollRestoration = ({ appNodeId }) => {
  const { pathname } = useLocation()
  const location = useLocation()
  const history = useHistory()
  const urlMap = React.useRef(new Map())
  const appNode = document.querySelector('#' + appNodeId)

  // UseEffect => La suite d'action est lancée si une modification / update arrive sur les données écoutées
  React.useEffect(() => {
    if (history.action !== 'POP') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      })
      appNode.style.removeProperty('min-height')
    }

    const unblock = history.block(tx => {
      urlMap.current.set(location.key, { minHeight: appNode.offsetHeight })

      const value = urlMap.current.get(tx.key)
      if (value) {
        appNode.style.minHeight = value.minHeight + 'px'
      }

      unblock()
    })
    // eslint-disable-next-line
  }, [pathname])

  return null
}

export default ScrollRestoration
