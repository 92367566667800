import React from 'react'
import { Carousel, LazySection } from '..'
import { RichTextField, DataLink } from '../prismic-elements'
import datastore from '../../datastore'

import './Targets.sass'

const Targets = ({ slice }) => {
  const layoutSliceTargets = datastore.layoutDoc.data.slice_targets

  return (
    <LazySection className='section section-richtext-full slice-targets'>
      <div className='is-full w-container'>
        <Carousel isSlideShow slideShowInterval={3000} className='section-richtext-full-container' pagerClassName='pager' pagerItemClassName='pager-item'>
          {
            layoutSliceTargets && layoutSliceTargets.map((item, index) => {
              const theme = item.theme ? item.theme.toLowerCase().replace(/ /gi, '-') : ''
              return (
                <div
                  key={index}
                  className={`section-richtext-full-item ${theme}`}
                >
                  <RichTextField field={item.title} Component='h2' className='section-title' />
                  <RichTextField field={item.description} Component='div' className='section-richtext-full-item-richtext w-richtext' />

                  <DataLink prismicLink={item.cta_link} className='button w-inline-block'>
                    <div>{item.cta_label}</div>
                  </DataLink>
                </div>
              )
            })
          }
        </Carousel>
      </div>
    </LazySection>
  )
}

export default Targets
