import React from 'react'
import { Carousel, SearchBar, LazySection } from '..'
import { RichTextField, DataLink } from '../prismic-elements'
import { useLocation, useParams, Link as RouterLink } from 'react-router-dom'
import { client, slicesFetchLinks, getJobOfferDetail, isRichTextEmpty } from '../../utils/prismicHelpers'
import { getPathWithCurrentLang } from '../../utils/helpers'
import Prismic from 'prismic-javascript'
import datastore from '../../datastore'

import './CurrentJobOffers.sass'

const CurrentJobOffers = ({ slice }) => {
  const [prismicData, setPrismicData] = React.useState({ jobOffers: null })
  const [pageError, setPageError] = React.useState(null)
  const { pathname } = useLocation()
  const { lang } = useParams()

  // UseEffect => La suite d'action est lancée si une modification / update arrive sur les données écoutées
  React.useEffect(() => {
    const fetchPrismicData = async () => {
      let error
      try {
        const jobOffersPredicates = [Prismic.Predicates.at('document.type', 'job_offer')]

        if (slice.primary.filter_job.id) jobOffersPredicates.push(Prismic.Predicates.at('my.job_offer.job', slice.primary.filter_job.id))
        if (slice.primary.filter_sector.id) jobOffersPredicates.push(Prismic.Predicates.at('my.job_offer.sector', slice.primary.filter_sector.id))
        if (slice.primary.filter_location.id) jobOffersPredicates.push(Prismic.Predicates.at('my.job_offer.location', slice.primary.filter_location.id))

        const jobOffers = await client.query(
          jobOffersPredicates,
          { lang, orderings: '[document.last_publication_date desc]', fetchLinks: slicesFetchLinks, pageSize: 9 }
        )

        if (jobOffers) {
          setPrismicData({ jobOffers: jobOffers.results })
        } else {
          error = 'Document was not found. Make sure it exists in your Prismic repository.'
          console.warn(error)
        }
      } catch (ex) {
        error = ex
        console.error(error)
      } finally {
        setPageError(error)
      }
    }

    fetchPrismicData()
    // eslint-disable-next-line
  }, [pathname])

  if (pageError) return null

  const currentJobOffersData = datastore.layoutDoc.data.slice_current_job_offers[0]
  const title = isRichTextEmpty(slice.primary.title) ? currentJobOffersData.title : slice.primary.title

  return (
    <LazySection className={`section section-job-offers ${slice.slice_label || ''}`}>
      <div className='w-container'>
        <RichTextField field={title} Component='h2' className='section-title' />
        <div className='section-job-offers-search'>
          <SearchBar
            className='is-discret'
            defaultValueJobs={slice.primary.filter_job.uid}
            defaultValueSectors={slice.primary.filter_sector.uid}
            defaultValueLocations={slice.primary.filter_location.uid}
          />
          <RouterLink to={getPathWithCurrentLang(lang, '/applicants/jobs')} className='button is-light w-inline-block'>{currentJobOffersData.cta_label}</RouterLink>
        </div>
        <Carousel className='section-job-offers-cards' pagerClassName='pager' pagerItemClassName='pager-item'>
          {
            prismicData.jobOffers && prismicData.jobOffers.map((item, index) => {
              return (
                <DataLink
                  prismicLink={item}
                  key={index}
                >
                  <div className='section-job-offers-cards-item'>
                    <RichTextField field={item.data.sector && item.data.sector.data && item.data.sector.data.title} Component='div' className='section-job-offers-cards-item-sector' />
                    <RichTextField field={item.data.title} Component='div' className='section-job-offers-cards-item-title' />
                    <div className='section-job-offers-cards-item-detail'>{getJobOfferDetail(item)}</div>
                  </div>
                </DataLink>
              )
            })
          }
        </Carousel>
      </div>
    </LazySection>
  )
}

export default CurrentJobOffers
