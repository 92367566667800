import React from 'react'
import { Helmet } from 'react-helmet'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../prismic-configuration'

const Meta = ({ pageDoc }) => {
  const pageUrl = window.location.href
  const pageTitle = getPageTitle(pageDoc) || 'MCA'
  const pageDescription = getPageDescription(pageDoc)
  const pageImageUrl = getPageImageUrl(pageDoc)

  return (
    <Helmet htmlAttributes={{ lang: pageDoc.lang }}>
      <title>{pageTitle}</title>
      <meta name='description' content={pageDescription} />

      {renderCanonical(pageUrl)}
      {renderHrefLangs(pageDoc, pageUrl)}
      {renderSlices(pageDoc.data.meta_body, pageUrl, pageTitle, pageDescription, pageImageUrl)}
    </Helmet>
  )
}

function getFirstParagraph (richText, textLimit = 300) {
  if (richText) {
    const text = RichText.asText(richText)
    let limitedText = text.substring(0, textLimit)

    if (text.length > textLimit) {
      // Cut only up to the last word and attach '...' for readability
      limitedText = `${limitedText.substring(0, limitedText.lastIndexOf(' '))}...`
    }

    return limitedText
  }

  return null
}

function getPageTitle (pageDoc) {
  let value = pageDoc.data.meta_title

  if (!value && pageDoc.data.title) {
    value = RichText.asText(pageDoc.data.title)
  }

  return value
}

function getPageDescription (pageDoc) {
  let value = pageDoc.data.meta_description

  // if page as description field
  if (!value && pageDoc.data.description) {
    value = getFirstParagraph(pageDoc.data.description, 140)
  } else if (pageDoc.data.body) {
    // find the first slice with richtext field
    for (let index = 0; index < pageDoc.data.body.length && !value; index++) {
      const slice = pageDoc.data.body[index]
      for (const key in slice.primary) {
        // use field name
        if (Object.hasOwnProperty.call(slice.primary, key)) {
          if (key === 'rich_text') {
            value = getFirstParagraph(slice.primary[key], 140)
            break
          }
        }
      }
    }
  }

  return value
}

function getPageImageUrl (pageDoc) {
  let value = null
  // find the first image field
  for (const key in pageDoc.data) {
    if (Object.hasOwnProperty.call(pageDoc.data, key)) {
      const field = pageDoc.data[key]
      // image field has dimensions property
      if (field && field.dimensions) {
        value = field.url
        break
      }
    }
  }

  return value
}

function renderCanonical (url) {
  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1)
  }
  return <link rel='canonical' href={url} />
}

function renderHrefLangs (pageDoc, canonicalUrl) {
  const alternateLanguages = pageDoc.alternate_languages && pageDoc.alternate_languages.length > 0 ? [...pageDoc.alternate_languages] : []
  alternateLanguages.push({ ...pageDoc })

  const url = new URL(canonicalUrl)

  // add x-default
  alternateLanguages.push({ lang: 'x-default' })

  // add region independant
  alternateLanguages.push({ ...pageDoc, ri: pageDoc.lang.split('-')[0] })
  if (pageDoc.alternate_languages && pageDoc.alternate_languages.length > 0) {
    for (let index = 0; index < pageDoc.alternate_languages.length; index++) {
      const al = pageDoc.alternate_languages[index]
      alternateLanguages.push({ ...al, ri: al.lang.split('-')[0] })
    }
  }

  return alternateLanguages.map((al) => {
    let hrefLang
    let href
    switch (al.lang) {
      case 'x-default':
        hrefLang = 'x-default'
        href = window.location.origin + linkResolver(al)
        break

      default:
        hrefLang = al.ri || al.lang
        href = window.location.origin + linkResolver(al)
        break
    }

    if (href.endsWith('/')) {
      href = href.substring(0, href.length - 1)
    }
    if (url.search) {
      href = href + '/' + url.search
    }

    return <link key={hrefLang} rel='alternate' href={href} hreflang={hrefLang} />
  })
}

// RB: 06/01/2021 : OLD version, Prismic now support multi UID on alternate language
// function renderHrefLangs (pageDoc, canonicalUrl) {
//   const alternateLanguages = pageDoc.alternate_languages && pageDoc.alternate_languages.length > 0 ? [...pageDoc.alternate_languages] : []
//   alternateLanguages.push({ lang: pageDoc.lang })

//   const defaultLang = datastore.apiDoc.languages[0].id

//   const url = new URL(canonicalUrl)
//   const match = new RegExp('^/(' + alternateLanguages.map(_ => _.lang).join('|') + ')?/?(.*)', 'i').exec(url.pathname)

//   // const lang = match[1]
//   const path = match[2]

//   // add x-default
//   alternateLanguages.push({ lang: 'x-default' })

//   // add region independant
//   alternateLanguages.push({ lang: pageDoc.lang, ri: pageDoc.lang.split('-')[0] })
//   if (pageDoc.alternate_languages && pageDoc.alternate_languages.length > 0) {
//     for (let index = 0; index < pageDoc.alternate_languages.length; index++) {
//       const al = pageDoc.alternate_languages[index]
//       alternateLanguages.push({ lang: al.lang, ri: al.lang.split('-')[0] })
//     }
//   }

//   return alternateLanguages.map((al) => {
//     let hrefLang
//     let href
//     switch (al.lang) {
//       case 'x-default':
//         hrefLang = 'x-default'
//         href = window.location.origin + '/' + path
//         break

//       case defaultLang:
//         hrefLang = al.ri || al.lang
//         href = window.location.origin + '/' + path
//         break

//       default:
//         hrefLang = al.ri || al.lang
//         href = window.location.origin + '/' + al.lang + '/' + path
//         break
//     }

//     if (href.endsWith('/')) {
//       href = href.substring(0, href.length - 1)
//     }
//     if (url.search) {
//       href = href + '/' + url.search
//     }

//     return <link key={hrefLang} rel='alternate' href={href} hreflang={hrefLang} />
//   })
// }

function renderSlices (sliceZone, pageUrl, pageTitle, pageDescription, pageImageUrl) {
  // if page has not have meta, we build it
  if (!sliceZone || sliceZone.length <= 0) {
    sliceZone = []

    sliceZone.push({
      slice_type: 'meta_opengraph',
      primary: {
        title: pageTitle,
        description: pageDescription,
        url: pageUrl,
        image: pageImageUrl && {
          url: pageImageUrl
        }
      }
    })
    sliceZone.push({
      slice_type: 'meta_twitter',
      primary: {
        title: pageTitle,
        description: pageDescription,
        url: pageUrl,
        image: pageImageUrl && {
          url: pageImageUrl
        }
      }
    })
  }

  return sliceZone.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case 'meta_opengraph': return OpenGraph(slice)
        case 'meta_twitter': return Twitter(slice)

        default:
      }
    })()
    return res
  })
}

function OpenGraph (slice) {
  const metas = []
  metas.push(<meta property='og:type' content='website' />)
  metas.push(<meta property='og:site_name' content='MCA' />)

  slice.primary.title && metas.push(<meta property='og:title' content={slice.primary.title} />)
  slice.primary.description && metas.push(<meta property='og:description' content={slice.primary.description} />)
  slice.primary.image && slice.primary.image.url && metas.push(<meta property='og:image' content={slice.primary.image.url} />)
  slice.primary.url && metas.push(<meta property='og:url' content={slice.primary.url} />)

  return metas
}

function Twitter (slice) {
  const metas = []
  metas.push(<meta name='twitter:card' content='summary' />)
  metas.push(<meta name='twitter:site' content='@mca' />)

  slice.primary.title && metas.push(<meta name='twitter:title' content={slice.primary.title} />)
  slice.primary.description && metas.push(<meta name='twitter:description' content={slice.primary.description} />)
  slice.primary.image && slice.primary.image.url && metas.push(<meta name='twitter:image:src' content={slice.primary.image.url} />)

  return metas
}

export default Meta
