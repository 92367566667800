import React from 'react'

export const TransitionStage = {
  entering: 'entering',
  entered: 'entered',
  exiting: 'exiting',
  exited: 'exited',
  appear: 'appear'
}

const useInOutTransition = (value, duration) => {
  const [state, setState] = React.useState(value ? TransitionStage.appear : TransitionStage.exited)
  const isInitialRun = React.useRef(true)
  const durationRef = React.useRef(duration)
  durationRef.current = duration // use latest

  // UseEffect => La suite d'action est lancée si une modification / update arrive sur les données écoutées
  React.useEffect(() => {
    if (isInitialRun.current) {
      isInitialRun.current = false
      return () => {}
    }

    setState(value ? TransitionStage.entering : TransitionStage.exiting)
    const tmId = setTimeout(() => {
      const next = value ? TransitionStage.entered : TransitionStage.exited
      setState(next)
    }, durationRef.current)

    return () => {
      if (tmId) clearTimeout(tmId)
    }
  }, [value])

  return state
}

export default useInOutTransition
