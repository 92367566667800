import React from 'react'
import { RichTextField } from '../prismic-elements'
import { LazySection } from '..'

import './AlternateGrid.sass'

const AlternateGrid = ({ slice }) => {
  return (
    <LazySection
      className='section section-alternate-grid'
    >
      <div className='w-container is-narrow'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <div className='section-alternate-grid-container'>
          {
          slice.items.map((item, index) => {
            return (
              <div key={index} className='section-alternate-grid-item'>
                {
                  item.image.url &&
                    <img
                      className='section-alternate-grid-item-image'
                      src={item.image.url}
                      loading='lazy'
                      alt={item.image.alt}
                      width={item.image.dimensions.width}
                      height={item.image.dimensions.height}
                    />
                }
                <div className='section-alternate-grid-item-text'>
                  <RichTextField field={item.description} />
                </div>
              </div>
            )
          })
        }
        </div>
      </div>
    </LazySection>
  )
}

export default AlternateGrid
