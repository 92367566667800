import React from 'react'
import { DataLink } from '../components/prismic-elements'
import Prismic from 'prismic-javascript'
import { apiEndpoint, accessToken } from '../prismic-configuration'
import { Markup } from 'interweave';

export const getJobOfferDetail = (jobOffer, filialedisplaytab = []) => {
  /*
  let detail = ''
  if (jobOffer.data.location && jobOffer.data.location.data) detail += jobOffer.data.location.data.name
  if (detail !== '' && jobOffer.data.contract_type) detail += ' | '
  if (jobOffer.data.contract_type) detail += jobOffer.data.contract_type
  return detail
  */
  let detail = ''
  if (jobOffer.data.city) {
    if (jobOffer.data.location && jobOffer.data.location.data) {
      detail += '<span style="display:none;">'
      detail += jobOffer.data.location.data.name
      detail += '</span>'
    }
  }
  else {
    if (jobOffer.data.location && jobOffer.data.location.data) detail += jobOffer.data.location.data.name
  }
  if (jobOffer.data.city)
  {
    if (detail !== '') {
      if (jobOffer.data.location && jobOffer.data.location.data) detail += '<span style="display:none;">'
      detail += ' | '
      if (jobOffer.data.location && jobOffer.data.location.data) detail += '</span>'
    }
    detail += '<span class="MCACityJob">' + jobOffer.data.city + '</span>'  
  }
  else
  {
    if (detail !== '' && jobOffer.data.location && jobOffer.data.location.data) detail += '<span style="display:none;"> | </span>'
    if (jobOffer.data.location && jobOffer.data.location.data) detail += '<span class="MCACityJob" style="display:none;">' + jobOffer.data.location.data.name + '</span>'
  }
  if (detail !== '' && jobOffer.data.contract_type) detail += ' | '
  if (jobOffer.data.contract_type) detail += jobOffer.data.contract_type
  let filialedisplay = ""
  const subsidiary = jobOffer.data.subsidiary_page.id || null
  if (subsidiary != null) {
    const subsidiarypage = filialedisplaytab.find(_ => _.id === subsidiary)
    if (subsidiarypage) {
      filialedisplay = subsidiarypage.data.name || jobOffer.data?.subsidiary || ''
    }
    else {
      filialedisplay = jobOffer.data?.subsidiary || ''
    }
  }
  else {
    filialedisplay = jobOffer.data?.subsidiary || ''
  }
  if (filialedisplay != "") {
    detail += '<span style="display:none;"> | </span>'
    detail += '<span class="MCAFilialeJob" style="display:none;">' + filialedisplay + '</span>'
  }
  if (jobOffer.lang != "") {
    detail += '<span style="display:none;"> | </span>'
    detail += '<span class="MCALocaleJob" style="display:none;">' + jobOffer.lang + '</span>'
  }
  return <Markup content={detail} />
}

export const slicesFetchLinks = [
  //
  'location.name',
  //
  'job.name',
  'job.title',
  'job.thumbnail',
  'job.hero_background',
  //
  'sector.name',
  'sector.title',
  'sector.description',
  'sector.thumbnail',
  'sector.hero_background',
  //
  'office_page.title',
  //
  'solution.name',
  'solution.title',
  'solution.description',
  'solution.thumbnail',
  'solution.hero_background',
  'solution.embed',
  //
  'project.title',
  'project.hero_background',
  'project.sector',
  //
  'testimonial.title',
  'testimonial.description',
  'testimonial.hero_background',
  'testimonial.embed',
  //
  'news.title',
  'news.description',
  'news.thumbnail',
  'news.hero_background'
]

// Helper function to convert Prismic Rich Text links to React Link components
// RB: I don't know why but no label from Prismic RichText on hyperlink...
export const customLink = (type, element, content, children, index) => {
  return (
    <DataLink prismicLink={element.data} key={index} className={element.label || ''}>
      {content}
    </DataLink>
  )
}

// Client method to query documents from the Prismic repo
export const client = Prismic.client(apiEndpoint, { accessToken })

export const isRichTextEmpty = (richtext) => !richtext || richtext.length === 0 || (richtext.length === 1 && richtext[0].text === '')
